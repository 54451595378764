var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"position-fixed top-0 left-0 bg-primary w-100 h-100 popupLogin p-5"},[_c('img',{staticClass:"bg-hero-image top-20",attrs:{"src":require('@/assets/images/businessLanding/header-right.png')}}),_c('b-container',{attrs:{"fluid":""}},[_c('nav',{staticClass:"d-flex justify-content-between align-items-center"},[_c('img',{staticClass:"brandImage",attrs:{"src":require('@/assets/images/whiteLogo.png'),"alt":"coFlow"}}),_c('span',{staticClass:"cursor-pointer",on:{"click":function($event){return _vm.$emit('close')}}},[_c('i',{staticClass:"las la-times text-white font-weight-bold font-size-32"})])]),_c('b-row',[_c('b-col',{staticClass:"m-auto",attrs:{"lg":"4","md":"8"}},[_c('h2',{staticClass:"text-center my-5 text_color"},[_vm._v("Business Dashboard")]),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [(_vm.showLogin)?_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.login)}}},[_c('b-card',{staticClass:"px-4 py-5 iq-border-radius-20"},[_c('b-form-group',{staticClass:"mb-4"},[_c('ValidationProvider',{ref:"email",attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"d-flex align-items-center position-relative"},[_c('i',{class:['las la-envelope text-secondary font-size-18 position-absolute',errors.length > 0 ?
                ' text-danger' : ''],staticStyle:{"z-index":"50","left":"15px"}}),_c('b-form-input',{class:[(errors.length > 0 ?
                ' is-invalid' : ''),'input_with_icon'],attrs:{"type":"text","placeholder":"Email"},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}})],1),_c('div',{staticClass:"invalid-feedback d-block"},[_c('div',[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1),_c('b-form-group',{staticClass:"mb-4"},[_c('ValidationProvider',{ref:"password",attrs:{"name":"Password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('div',{staticClass:"d-flex align-items-center position-relative"},[_c('i',{class:['las la-lock text-secondary font-size-18 position-absolute',errors.length > 0 ?
                ' text-danger' : ''],staticStyle:{"z-index":"50","left":"15px"}}),_c('b-form-input',{class:[(errors.length > 0 ?
                ' is-invalid' : ''),'input_with_icon'],attrs:{"type":"password","placeholder":"Password"},model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", $$v)},expression:"user.password"}})],1),_c('div',{staticClass:"invalid-feedback d-block"},[_c('div',[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1),_c('div',{staticClass:"d-flex justify-content-between align-items-center mt-2 px-1"},[_c('b-form-checkbox',{model:{value:(_vm.user.remember),callback:function ($$v) {_vm.$set(_vm.user, "remember", $$v)},expression:"user.remember"}},[_c('span',{staticClass:"text-secondary font-size-12"},[_vm._v("Remember me")])]),_c('span',{staticClass:"text-danger font-size-12 cursor-pointer",on:{"click":function($event){_vm.showLogin = false}}},[_vm._v("Forgot Password?")])],1)],1),_c('div',{staticClass:"d-flex justify-content-center mt-5"},[(_vm.loginLoading)?_c('spinner-loading',{staticClass:"gradient-orange-button m-auto mt-4 w-50 py-3",attrs:{"text":"Checking"}}):_c('b-button',{staticClass:"gradient-orange-button m-auto mt-4 w-50 py-3",attrs:{"type":"submit"}},[_vm._v("Login")])],1)],1):_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.resetPassword)}}},[_c('b-card',{staticClass:"px-4 pt-5 pb-3 iq-border-radius-20"},[(!_vm.showSuccessText)?_c('b-form-group',{staticClass:"mb-4"},[_c('ValidationProvider',{ref:"email",attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('div',{staticClass:"d-flex align-items-center position-relative"},[_c('i',{class:['las la-envelope text-secondary font-size-18 position-absolute',errors.length > 0 ?
                ' text-danger' : ''],staticStyle:{"z-index":"50","left":"15px"}}),_c('b-form-input',{class:[(errors.length > 0 ?
                ' is-invalid' : ''),'input_with_icon'],attrs:{"type":"text","placeholder":"Email"},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}})],1),_c('div',{staticClass:"invalid-feedback d-block"},[_c('div',[_vm._v(_vm._s(errors[0]))])])]}}],null,false,1331367062)})],1):_c('p',{staticClass:"gradient-blue-text"},[_vm._v(" A temporary password has been sent to your email. ")]),(!_vm.showSuccessText)?_c('div',{staticClass:"d-flex justify-content-end align-items-center mt-2 px-1"},[_c('span',{staticClass:"text-primary font-size-12 cursor-pointer",on:{"click":function($event){_vm.showLogin = true}}},[_vm._v("Return to login")])]):_vm._e()],1),(!_vm.showSuccessText)?_c('div',{staticClass:"d-flex justify-content-center mt-5"},[(_vm.loginLoading)?_c('spinner-loading',{staticClass:"gradient-orange-button m-auto mt-4 w-50 py-3",attrs:{"text":"Checking"}}):_c('b-button',{staticClass:"gradient-orange-button m-auto mt-4 w-50 py-3",attrs:{"type":"submit"}},[_vm._v("Reset Password")])],1):_c('div',{staticClass:"d-flex justify-content-center mt-5"},[_c('b-button',{staticClass:"gradient-orange-button m-auto mt-4 w-50 py-3",attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();_vm.showLogin = true; _vm.showSuccessText = false}}},[_vm._v("Return to login")])],1)],1)]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }